<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:90vh">
			<pane min-size="10" size="35">
			<div style="max-height: 100%" class="overflow-y-auto">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:loading="loading" loading-text="加载中..." :show-select="canAccept">
					<template v-slot:top>
						<div class="d-flex flex-wrap pt-2 pb-1 pl-2" style="gap:12px;">
        					<v-select persistent-placeholder placeholder="全部" clearable :items="['已确认','未确认']" v-model="acceptfilter"
                                dense hide-details style="flex: 0 1 auto;max-width:130px;" @change="fetchData"/>
							<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto;max-width:180px;"
								append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
							<v-btn v-if="canAccept" color="primary" @click.stop="exportXLS">导出</v-btn>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-icon :disabled="!!item.HzPre" color="#03A9F4" class="mr-2" @click.stop="showDlg(item)">mdi-pencil</v-icon>
					</template>
                    <template v-slot:item.progress.saveHzPre="{ item }">
                        {{formatTime(item.progress.saveHzPre)}}
                    </template>
                    <template v-slot:item.progress.acceptHzPre="{ item }">
                        {{formatTime(item.progress.acceptHzPre)}}
                    </template>
                    <template v-slot:item.HzPre.date="{ item }">
                        {{item.HzPre ? formatDate(item.HzPre.date) : ''}}
                    </template>
					<template v-slot:footer.prepend>
						<div v-if="canAccept">
                            <v-btn color="primary" @click.stop="accept" style="width:100px">确认</v-btn>
                            <v-btn color="primary" @click.stop="cancel" outlined style="width:100px;margin-left:8px;">放弃预约</v-btn>
						</div>
					</template>
				</v-data-table>
			</div>
			</pane>
            <!--
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto pl-4 pt-4">
				</div>
			</pane>
            -->
		</splitpanes>
		<huazhao-pre v-model="preDlg" :orderid="curid" @change="fetchData"/>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate, calculateAge} from '../utils'
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'
	import HuazhaoPre from '../components/HuazhaoPre.vue'

	export default {
		data() {
			return {
				authed: false,
				canEdit: false,
				headers: [
                    {text:'填写', value:'actions', width:60, sortable: false},
                    {text:'订单号', value:'_id', width:120},
					{text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'出生日期', value:'pii.dob', width:120},
                    {text:'电话', value:'pii.phone', width:100},
					{text:'证件类型', value:'pii.idtype', width:100},
					{text:'证件号', value:'pii.idcard', width:120},
                    {text:'健康顾问', value:'consultantName', width:100},
					{text:'申请时间', value:'progress.saveHzPre', width:180},
					{text:'确认时间', value:'progress.acceptHzPre', width:180},
					{text:'预约日期', value:'HzPre.date', width:120},
					{text:'分院', value:'HzPre.branch', width:80},
					{text:'付款方式', value:'HzPre.payway', width:100},
					{text:'加项', value:'HzPre.supplement', width:150},
					{text:'备注', value:'HzPre.comment', width:180},
				],
				items: [],
				loading: false,
				selected: [],
				options: {},
				totalItems: 0,
				pname: '',
                acceptfilter: null,
				preDlg: false,
				curid: '',
			}
		},
		mounted() {
			this.authed = this.$hasPrivilege(['华兆','健康顾问']);
            this.canAccept = this.$hasPrivilege(['华兆']);
			if (!this.authed) return;
			if (this.canAccept) this.$set(this.headers, 0, {text:'填写', value:'actions', width:10, sortable: false, align:' d-none'}); //d-none前的空格是必要的
			this.formatTime = formatTime;
			this.formatDate = formatDate;
			this.fetchData();
		},
		methods: {
			async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getFilter() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = {};
				if (this.$hasPrivilege('华兆')) {
					//admin也有此权限
					filter['HzPre'] = _.exists(true);
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter['consultantId'] = u.consultantId;
				}
                filter['opts.PRE'] = '华兆';
                if (this.acceptfilter === '已确认') {
                    filter['progress.acceptHzPre'] = _.exists(true);
                } else if (this.acceptfilter === '未确认') {
                    filter['progress.acceptHzPre'] = _.exists(false);
                }
				if (this.pname) {
					filter['pii.username'] = this.pname;
				}
				filter.hide = _.neq(true);
				return filter;
			},
            async accept() {
				if (this.selected.length === 0) return;
				const ids = this.selected.map(x => x._id);
				try {
					this.loading = true;
					await this.$tcbapp.callFunction({
						name:"wp2mp",
						data:{
							funcname:'acceptHzPre',
							data: {
								ids,
							}
						}
					});
					this.loading = false;
					await this.fetchData();
				} catch(err) {
					console.error(err);
				}
            },
            async cancel() {
				if (this.selected.length === 0) return;
				const names = this.selected.map(x => x.pii.username);
				const res = await this.$dialog.warning({
					text: `确定要放弃${names}的预约吗？`,
					title: '提示'
				});
				if (!res) return;
				const ids = this.selected.map(x => x._id);
				try {
					this.loading = true;
					await this.$tcbapp.callFunction({
						name:"wp2mp",
						data:{
							funcname:'cancelHzPre',
							data: {
								ids,
							}
						}
					});
					this.loading = false;
					await this.fetchData();
				} catch(err) {
					console.error(err);
				}
            },
			async showDlg(item) {
				this.curid = item._id;
				this.preDlg = true;
			},
			async exportXLS() {
				const header = [[
					'姓名',
					'性别',
					'出生日期',
					'电话',
					'证件类型',
					'证件号',
					'健康顾问',
					'申请时间',
					'预约日期',
					'分院',
					'付款方式',
					'加项',
					'备注',
				]];
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const data = res.data.map(x => [
					x.pii.username,
					x.pii.gender,
					x.pii.dob,
					x.pii.phone,
					x.pii.idtype,
					x.pii.idcard,
					x.consultantName,
					formatTime(x.progress.saveHzPre),
					x.HzPre ? formatDate(x.HzPre.date) : '',
					x.HzPre?.branch || '',
					x.HzPre?.payway || '',
					x.HzPre?.supplement || '',
					x.HzPre?.comment || '',
				]);
				const t = '华三术前';
				const filename = t + ".xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data));
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
			}
		},
		components: { Splitpanes, Pane, HuazhaoPre }
	}
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
	background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
	background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
	background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
	height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
	width: 60px;
	height: 1.5px
}
</style>