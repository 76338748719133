<template>
	<v-dialog persistent v-model="dlgPre" width="500">
		<v-card>
			<v-card-title>华兆术前</v-card-title>
			<v-card-text>
				<v-form ref="formPre">
					<div class="d-flex flex-warp" style="gap:12px;">
						<v-menu v-model="menuPre" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="HzPre.date" label="预约日期" readonly v-bind="attrs"
									v-on="on" style="flex: 1 1 40%;" :rules="cnltRules"></v-text-field>
							</template>
							<v-date-picker v-model="HzPre.date" @input="menuPre = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-select label="体检分院" :items="['东环','西环']" v-model="HzPre.branch" style="flex: 1 1 40%;" :rules="cnltRules"/>
						<v-select label="付款方式" :items="['检前交费','统一付款']" v-model="HzPre.payway" style="flex: 1 1 40%;" :rules="cnltRules"/>
					</div>
					<v-text-field label="加项" v-model="HzPre.supplement"/>
					<v-text-field label="备注" v-model="HzPre.comment"/>
				</v-form>
			</v-card-text>
			<v-divider/>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" :loading="loading" @click.stop="savePre">确定</v-btn>
				<v-btn @click.stop="exitDlg">取消</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		props: {
			value: false,
			orderid: '',
		},
		data() {
			return {
				loading: false,
				menuPre: false,
				HzPre: {
					date: '',
					branch: '',
					payway: '',
					supplement: '',
					comment: '',
				},
				cnltRules: [v => !!v || '此项为必填'],
			}
		},
		computed: {
			dlgPre: {
				get() {
					return this.value;
				},
				set(v) {
					if (!v) {
						this.$emit('input', false);
					}
				}
			},
		},
		watch: {
			value(v) {
				if (v) {
					this.HzPre = this.$options.data().HzPre;
					this.$nextTick(() => this.$refs.formPre.reset());
				}
			}
		},
		methods: {
			exitDlg() {
				this.dlgPre = false;
				this.$emit('close');
			},
			async savePre() {
				this.$refs.formPre.validate();
				if (!this.$refs.formPre.validate()) return;
				this.loading = true;
				try {
					const r = await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'saveHzPre',
						data: {
							id: this.orderid,
							HzPre: this.HzPre,
						}
					}});
					this.$emit('change', this.orderid);
					this.dlgPre = false;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
		}
	}
</script>
